import React, { useState, useEffect, useRef, useMemo } from 'react';
import useSWR from 'swr';
import {
  COMING_SOON,
  GIFT_CARDS,
  EGIFT_CARD,
  PRODUCT_TYPES_WITH_NO_SHIPPING_OPTIONS,
  POSA_GIFT,
} from 'helpers/constants/aafes';
import useGATrackAddActions from 'helpers/hooks/gaEvents/useGATrackAddActions';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { useGbTracker } from 'helpers/utils/gbTracker';
import { isVeteranAccount } from 'helpers/utils/isVeteranAccount';
import { getDefaultShippingMethod } from 'helpers/utils/pdpShippingMethod';
import { priceInUSD } from 'helpers/utils/priceConverter';
import { tablet } from 'helpers/utils/screensizes';
import { fetchApiHub, revalidateOptions, useAccount, useCart, useCustomMessage } from 'frontastic';
import AdditionalServicesModal from '../../Modals/AdditionalServicesModal';
import AddOn from './AddOn';
import AddToCart from './addToCart';
import AgeRestricted from './ageRestricted';
import Breadcrumb from './breadcrumb';
import ComingSoon from './comingSoon';
import ExchangePlusInfo from './exchangePlusInfo';
import Iconography from './iconography';
import ImageGallery from './ImageGallery';
import NonPurchasableProductInfoMessage from './nonPurchasableProductInfoMessage';
import PayYourWayInfo from './payYourWayInfo';
import PreOrder from './preOrder';
import ProductHeader from './ProductHeader';
import ProductInfo from './ProductInfo';
import QuantitySelector from './QuantitySelector';
import ShippingOptions from './ShippingOptions';
import Subscriptions from './Subscriptions';

export interface SelectedPicker {
  [key: string]: string;
}

const ProductReview = ({
  product,
  variant,
  onChangeVariantIdx,
  onAddToWishlist,
  showLogin,
  isGuestPdp,
  studioConfig,
  errorMessage,
  setErrorMessage,
  pdpDroplets,
}) => {
  const [isTablet] = useMediaQuery(tablet);
  const { account, loggedIn } = useAccount();
  const { addItem, data: cartList } = useCart();
  const cid = account?.cid;
  const { getCustomMessage } = useCustomMessage();
  const [personalizationLinesData, setPersonalizationLinesData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [added, setAdded] = useState<boolean>(false);
  const [subscriptionsFrequencySettings, setSubscriptionsFrequencySettings] = useState<string>();
  const [quantity, setQuantity] = useState<number>(1); // item quantity
  const [shippingMethod, setShippingMethod] = useState(getDefaultShippingMethod(variant));
  const [maxQuantity, setMaxQuantity] = useState<number>(variant?.attributes?.maxQty);
  const [showServicesModal, setServicesModal] = useState(false);
  const hideDeliveryOptions = useRef(false);
  const selectedChannel = useRef(null);
  const { setIsMiniCartOpen, isAddItemAfterLogin, setIsAddItemAfterLogin } = useCart();
  const refProducts = variant?.attributes?.referencedProducts;
  const additionalService = variant?.attributes?.referencedProducts?.length;
  const PersonalizationRequireds = variant?.attributes?.isPersonalizationRequired;
  const [showProductFrequency, setshowProductFrequency] = useState(false);
  const { trackAddToCart } = useGATrackAddActions();
  const [isPersonalization, setIsPersonalization] = useState(false);
  const isUseFacilityBasedPrice = variant?.attributes?.isUseFacilityBasedPrice;
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [selectedPicker, setSelectedPicker] = useState<SelectedPicker>(variant?.picker);

  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const isPickUp = shippingMethod?.isPickupInStore;
  const variantIsShipToStore = variant?.attributes?.isShipToStore;
  const variantIsPickUpInStore = variant?.attributes?.isPickupInStore;
  const variantIsOnStock = variant?.isOnStock;
  const variantStoreIsOnStock = variant?.isOnStockPickupInStore;
  const variantPrice = variant?.price?.centAmount;
  const variantStorePrice = variant?.storePrice?.centAmount;
  const isBothStoreShippingMethod = variantIsPickUpInStore && variantIsShipToStore;
  const soldOutCTA = formatProductMessage({ id: 'sold.out', defaultMessage: 'Sold Out' });
  const addToCartCTA = formatProductMessage({ id: 'bag.add', defaultMessage: 'Add to Cart' });
  const outOfStockCTA = formatProductMessage({ id: 'outOfStock', defaultMessage: 'Out of stock' });
  const preOrderCTA = formatProductMessage({ id: 'pre.order', defaultMessage: 'Pre Order' });
  const [getAllCustomMessages, setGetCustomMessages] = useState(null);
  const isAddToCartDisabledForPUIS = useMemo(() => {
    if (isVeteranAccount(account)) {
      return true;
    }
    const itemDepartmentCode: string = variant?.attributes?.departmentCode;
    const storeRestrictedDepartmentCodes: string[] = account?.defaultStore?.restrictedDepartmentCodes ?? [];
    if (
      itemDepartmentCode &&
      storeRestrictedDepartmentCodes?.length > 1 &&
      storeRestrictedDepartmentCodes?.includes(itemDepartmentCode)
    ) {
      return true;
    }
    if (isBothStoreShippingMethod) {
      if (variantStoreIsOnStock) {
        if (isUseFacilityBasedPrice) {
          return !(variantStorePrice > 0);
        } else {
          return !(variantStorePrice > 0 || variantPrice > 0);
        }
      } else {
        return !(variantIsOnStock && variantPrice > 0);
      }
    } else {
      if (variantIsShipToStore) {
        return !(variantIsOnStock && variantPrice > 0);
      } else {
        if (variantStoreIsOnStock) {
          if (isUseFacilityBasedPrice) {
            return !(variantStorePrice > 0);
          } else {
            return !(variantStorePrice > 0 || variantPrice > 0);
          }
        }
      }
    }
    return true;
  }, [variant, account]);

  const isAddToCartDisabledForSTA = () => !(variantIsOnStock && variantPrice > 0);

  const addToCartLabel = useMemo(() => {
    if (
      product?.variants?.every((variant: any) => !variant?.isOnStock) &&
      !variant?.attributes?.isShipToStore &&
      !variant?.attributes?.isPickupInStore &&
      !product?.isPreOrderable
    ) {
      return soldOutCTA;
    }
    if (isPickUp) {
      if (isBothStoreShippingMethod) {
        if (!variantStoreIsOnStock && !variantIsOnStock) {
          return outOfStockCTA;
        }
      } else {
        if ((variantIsShipToStore && !variantIsOnStock) || (variantIsPickUpInStore && !variantStoreIsOnStock)) {
          return outOfStockCTA;
        }
      }
    }
    if (!isPickUp) {
      if (!variantIsOnStock) {
        return outOfStockCTA;
      }
    }
    if (product?.isPreOrderable) {
      return preOrderCTA;
    }
    return addToCartCTA;
  }, [variant, product, account, shippingMethod]);

  const IsAddToCartDisabled = () => {
    if (!variant?.isPickupInStore && !variant?.isShipToAddress) {
      return true;
    }
    if (isPickUp) {
      return isAddToCartDisabledForPUIS;
    } else {
      return isAddToCartDisabledForSTA();
    }
  };

  const getAddToCartLabel = () => {
    if (product?.isPreOrderableComingSoon) {
      return outOfStockCTA;
    }
    if (!isVariantFound) {
      return soldOutCTA;
    }

    return addToCartLabel;
  };

  const getAddToCartDisabled = () => {
    if (product?.isPreOrderableComingSoon) {
      return true;
    }
    if (!isVariantFound) {
      return true;
    }

    return IsAddToCartDisabled();
  };

  const isVariantFound = useMemo(() => {
    const availablePickers = product?.pickers ?? [];
    if (availablePickers?.length < 1 || !selectedPicker) {
      return true;
    } else {
      return product?.variants?.some((variant) =>
        Object.keys(selectedPicker).every((key) => variant?.picker?.[key] === selectedPicker?.[key]),
      );
    }
  }, [selectedPicker]);
  const { trackEvent } = useGbTracker();

  useEffect(() => {
    trackEvent(
      'viewProduct',
      {
        product: {
          productId: product?.productId,
          title: product?.name,
          price: priceInUSD(product?.price),
          collection: variant?.[0]?.attributes?.customProductType,
          category: product?.categories?.[0]?.name,
          sku: `sku-${variant?.sku}`,
        },
      },
      cid,
    );
  }, [cid, loggedIn]);

  const handleAddToCart = async ({ isPickupInStore, channel, addToCartConfirm, additionalPayload }: any) => {
    //the whole idea is to have a single trigger point for addToCart
    const isOnlyVasPresentForPuis =
      isPickupInStore && additionalService === 1 && refProducts?.[0]?.name === 'DELIVERY_SERVICE';
    const isBronzeDeliveryVASPresentForPuis =
      isOnlyVasPresentForPuis &&
      refProducts?.[0]?.value?.some((product) =>
        product?.variants?.some((variant) => variant?.sku === studioConfig?.deliveryServiceProductSKU),
      );

    if (showProductFrequency && !subscriptionsFrequencySettings) {
      setErrorMessage(
        formatCommonMessage({
          id: 'selectSubscription',
          defaultMessage: 'Please select Subscription',
        }),
      );
      return false;
    }
    if (!loggedIn && !isGuestPdp) {
      let continueAsGuestEligible = false;
      continueAsGuestEligible = studioConfig?.['guestProductKeys']?.includes(product?.productId);
      showLogin(continueAsGuestEligible, true);
      return;
    }
    if (PersonalizationRequireds && !isPersonalization) {
      try {
        const customMessage = await getCustomMessage({
          messageKey: 'personalizationInfoRequired',
        });
        setErrorMessage(customMessage);
      } catch (error) {
        console.error('Error fetching customMessage - personalizationInfoRequired :', error);
        setErrorMessage('Please ,provide at least 1 embroided message.');
      }
      return;
    }
    if (addToCartConfirm) {
      setErrorMessage(null);
      setLoading(true);
      const cartResponse = await addItem(
        variant,
        quantity,
        channel,
        personalizationLinesData,
        !channel ? subscriptionsFrequencySettings : undefined,
        additionalPayload?.additionalSkus || undefined,
        isGuestPdp,
      );
      if (cartResponse?.['errorCode']) {
        setErrorMessage(cartResponse?.['body']);
      } else {
        setIsMiniCartOpen(true);
        trackAddToCart({ product, variant, quantity, account });
      }
      setLoading(false);
      setAdded(true);
      return false;
    } else {
      if (additionalService > 0 && !showServicesModal && !isOnlyVasPresentForPuis) {
        //opens the modal for the first time if additionalService exist
        hideDeliveryOptions.current = isPickupInStore;
        selectedChannel.current = isPickupInStore ? channel : null;
        setServicesModal(true);
        return false;
      } else {
        //adds the items to the cart
        handleAddToCart({
          addToCartConfirm: true,
          channel,
          additionalPayload: {
            additionalSkus: isBronzeDeliveryVASPresentForPuis ? [studioConfig?.deliveryServiceProductSKU] : undefined,
          },
          isSkipLoginCheck: true,
        });
      }
    }
    trackEvent(
      'addToCart',
      {
        cart: {
          id: cartList?.cartId,
          items: [
            {
              productId: product?.productId,
              title: product?.name,
              price: priceInUSD(product?.price),
              quantity: quantity,
              collection: variant?.[0]?.attributes?.customProductType,
              category: product?.categories?.[0]?.name,
              sku: `sku-${variant?.sku}`,
            },
          ],
        },
      },
      cid,
    );
  };

  useEffect(() => {
    if (added) {
      setTimeout(() => {
        setAdded(false);
      }, 1000);
    }
  }, [added]);

  useEffect(() => {
    setErrorMessage(null);
  }, [variant]);

  useEffect(() => {
    //If product type is e-gift card then we are setting max quantity to 1
    if (
      EGIFT_CARD?.includes(variant?.attributes?.customProductType) ||
      POSA_GIFT?.includes(variant?.attributes?.customProductType)
    ) {
      setMaxQuantity(1);
    } else {
      //Fall back to the project level maxQty from the studio if there is no product level max quantity
      if (!maxQuantity) {
        if (studioConfig?.productMaxQty) {
          setMaxQuantity(studioConfig?.productMaxQty);
        }
      }
    }
  }, []);
  useEffect(() => {
    let timeoutId = null;
    if (isAddItemAfterLogin) {
      timeoutId = setTimeout(() => {
        setIsAddItemAfterLogin(null);
      }, 1000);
      !getAddToCartDisabled() && handleAddToCart({});
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [isAddItemAfterLogin]);

  useEffect(() => {
    if (!shippingMethod?.isPickupInStore && !shippingMethod?.isShipToAddress) {
      setShippingMethod(getDefaultShippingMethod(variant));
    }
  }, [account?.defaultStore?.key, variant?.isOnStock, variant?.isOnStockPickupInStore]);

  useEffect(() => {
    if (product?.productId) {
      setShippingMethod(getDefaultShippingMethod(variant));
    }
  }, [product?.productId]);

  const { data } = useSWR(
    `/action/customObjects/getCustomMessages?messages=outletFinalSaleNoReturnPromo`,
    fetchApiHub,
    revalidateOptions,
  );
  useEffect(() => {
    setGetCustomMessages(data);
  }, [data]);
  return (
    <div>
      {!isGuestPdp && isTablet && <Breadcrumb categories={product?.categories} />}
      <ProductHeader
        name={product?.name}
        badge={product?.marketingBug}
        sku={variant?.sku}
        productId={product?.productId}
        isGuestPdp={isGuestPdp}
        variant={variant}
        studioConfig={studioConfig}
      />{' '}
      <div className="grid grid-cols-1 gap-x-6 gap-y-2 md:grid-cols-2 xl:grid-cols-[minmax(0,47%)_1fr] xl:gap-y-[3%]">
        <section>
          <ImageGallery product={product} variant={variant} />
        </section>
        {variant?.attributes?.searchable === false ? (
          <div className="inline-block">
            <NonPurchasableProductInfoMessage />
          </div>
        ) : (
          <section className="grid md:grid-cols-1 xl:h-max xl:grid-cols-[1fr_auto]">
            <div className="xl:xl:border-r-[1px] xl:border-[#00000033] xl:pr-5">
              <ProductInfo
                product={product}
                variant={variant}
                personalizationLinesData={setPersonalizationLinesData}
                onChangeVariantIdx={onChangeVariantIdx}
                showLogin={showLogin}
                errorMessage={errorMessage}
                isPickUp={shippingMethod?.isPickupInStore}
                isUseFacilityBasedPrice={isUseFacilityBasedPrice}
                setIsPersonalization={setIsPersonalization}
                studioConfig={studioConfig}
                selectedPicker={selectedPicker}
                setSelectedPicker={setSelectedPicker}
                pdpDroplets={pdpDroplets}
              />
            </div>
            <div className="xl:max-w-xs xl:justify-self-end">
              <div className="xl:pl-4">
                {!GIFT_CARDS.includes(variant?.attributes?.customProductType) && studioConfig?.isEnablePayYourWay && (
                  <PayYourWayInfo studioConfig={studioConfig} />
                )}
                {variant?.attributes?.isAddon && (
                  <div className="pt-4 md:pt-1">
                    <AddOn />
                  </div>
                )}
                {product?.categories?.map((category) => {
                  return (
                    (category?.name === studioConfig?.outletCategoryName ||
                      category?.categoryId === studioConfig?.outletCategoryId) && (
                      <div className="pdp-outlet mt-3">
                        <span className="w-fit text-sm font-bold leading-5 text-gray-900 sm:m-0">
                          {getAllCustomMessages?.outletFinalSaleNoReturnPromo}
                        </span>
                      </div>
                    )
                  );
                })}

                {variant?.attributes?.ageRestriction && <AgeRestricted className="text-blue-900" />}
                {variant?.attributes?.customProductType === COMING_SOON ? (
                  <ComingSoon />
                ) : (
                  <>
                    {!PRODUCT_TYPES_WITH_NO_SHIPPING_OPTIONS?.includes(variant?.attributes?.customProductType) &&
                      !product?.isPreOrderableComingSoon &&
                      loggedIn && (
                        <ShippingOptions
                          product={product}
                          variant={variant}
                          quantity={quantity}
                          setQuantity={setQuantity}
                          handleAddToCart={handleAddToCart}
                          shippingMethod={shippingMethod}
                          setShippingMethod={setShippingMethod}
                          maxQuantity={product?.isPreOrderable ? variant?.attributes?.preOrderMaxQty : maxQuantity}
                          showProductFrequency={showProductFrequency}
                          isAddToCartDisabledForPUIS={isAddToCartDisabledForPUIS}
                          isAddToCartDisabledForSTA={isAddToCartDisabledForSTA()}
                        />
                      )}
                    {variant?.attributes?.isSubscribable &&
                      shippingMethod?.isShipToAddress &&
                      variant?.attributes?.productFrequences?.length > 0 && (
                        <Subscriptions
                          variant={variant}
                          subscriptionsFrequencySettings={subscriptionsFrequencySettings}
                          setSubscriptionsFrequencySettings={setSubscriptionsFrequencySettings}
                          showProductFrequency={showProductFrequency}
                          setshowProductFrequency={setshowProductFrequency}
                        />
                      )}
                    {(product?.isPreOrderable || product?.isPreOrderableComingSoon) && (
                      <PreOrder preOrderStreetDate={variant?.attributes?.preOrderStreetDate} />
                    )}
                    <QuantitySelector
                      productType={variant?.attributes?.customProductType}
                      quantity={getAddToCartDisabled() ? 0 : quantity}
                      setQuantity={setQuantity}
                      maxQuantity={product?.isPreOrderable ? variant?.attributes?.preOrderMaxQty : maxQuantity}
                      isAddToCartCtaDisable={getAddToCartDisabled()}
                    />
                    <AddToCart
                      addToCartLabel={getAddToCartLabel()}
                      isAddToCartDisabled={getAddToCartDisabled()}
                      added={added}
                      loading={loading}
                      handleAddToCart={async () =>
                        await handleAddToCart({
                          isPickupInStore: isPickUp,
                          channel: isPickUp ? account?.defaultStore?.key : undefined,
                        })
                      }
                    />
                  </>
                )}
                {variant?.attributes?.isDropShip && <ExchangePlusInfo />}
                <Iconography
                  productId={product?.productId}
                  onAddToWishlist={onAddToWishlist}
                  showLogin={showLogin}
                  isOnWishlist={product?.isOnWishlist}
                />
              </div>
            </div>
          </section>
        )}
      </div>
      {showServicesModal && (
        <AdditionalServicesModal
          servicesModal={showServicesModal}
          setServicesModal={setServicesModal}
          additionalServices={refProducts}
          lineItem={product}
          addOnItemQty={quantity}
          parentSku={variant}
          channel={selectedChannel.current}
          personalizationLines={personalizationLinesData}
          subscriptionsFrequency={subscriptionsFrequencySettings}
          isCart={false}
          hideDeliveryOptions={hideDeliveryOptions.current}
          handleAddToCart={handleAddToCart}
        />
      )}
    </div>
  );
};
export default ProductReview;
